//首页相关
import { $http } from '../plugins/axios'
const { post, get } = $http



//分页查询车型信息
export const pageAllCar = (pageNum, pageSize) => {
    return get(`/api/system/enterpriseUser/pageAllCar?pageNum=${pageNum}&pageSize=${pageSize}`)
}
//查询平台服务
export const pageAllCarService = () => {
    return get('/api/system/enterpriseUser/pageAllCarService')
}
//计算距离
export const distanceCalculation = (originsAddress, destinationsAddress) => {
    return get(
        `/system/enterpriseOrder/distanceCalculation?originsAddress=${originsAddress}&destinationsAddress=${destinationsAddress}`
    )
}
//获取用车时长列表
export const listCharterTime = () => {
    return get('/api/system/enterpriseUser/listCharterTime')
}
//专车生成订单
export const orderGeneration = (params) => {
    return post('/system/enterpriseOrder/orderGeneration', params)
}
//专车生成订单
export const charteredBusOrderGeneration = (params) => {
    return post('/system/enterpriseOrder/charteredBusOrderGeneration', params)
}
//分页查询我的订单
export const pageAllEnterpriseOrder = (pageNum, pageSize, orderStatus, searchType, searchContent) => {
    return get(
        `/system/enterpriseOrder/pageAllEnterpriseOrder?pageNum=${pageNum}&pageSize=${pageSize}&orderStatus=${orderStatus}&searchType=${searchType}&searchContent=${searchContent}`
    )
}
//查询订单详情信息
export const getEnterpriseOrderDetail = (id) => {
    return get(`/system/enterpriseOrder/getEnterpriseOrderDetail?id=${id}`)
}
//取消订单
export const cancelOrder = (id) => {
    return post(`/system/enterpriseOrder/cancelOrder?id=${id}`)
}
//删除订单
export const delOrder = (id) => {
    return post(`/system/enterpriseOrder/delOrder?id=${id}`)
}