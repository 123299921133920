<template>
    <div>
        <div class="header">
            <div class="right" @click="onHome">
                <img src="../assets/images/bg_home.png" alt="" class="logo" />
                <div>{{ $t('PH.default') }}</div>
            </div>
            <div class="left">
                <div v-if="isLogin" @click="goOrder">{{ $t('PH5') }}</div>
                <div @click="$store.commit('SHOWLOGIN')" v-if="!isLogin">{{ $t('I3') }}</div>
                <div @click="$store.commit('SHOWREGISTER')" v-if="!isLogin">{{ $t('PH2') }}</div>
                <div v-if="isLogin">{{ info.enterpriseName }}</div>
                <div v-if="isLogin" @click="isEdit = true">{{ $t("my9") }}</div>
                <el-select v-model="value" @change="change">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="tips">
            <el-dialog :title="$t('M48')" :visible.sync="$store.state.showLogin" width="440px" center top="30vh">
                <div class="info">
                    <div class="input-box" v-if="loginWay == 1">
                        <el-input :placeholder="$t('I1')" v-model="login.phone"></el-input>
                    </div>
                    <div class="input-box" v-else>
                        <el-input :placeholder="$t('I20')" v-model="login.email"></el-input>
                    </div>
                    <div class="input-box">
                        <el-input :placeholder="$t('I2')" show-password v-model="login.password"></el-input>
                    </div>
                </div>
                <span slot="footer">
                    <div class="bottom">
                        <div class="tit" v-if="loginWay == 1" @click="loginWay = 2">{{ $t('I6') }}</div>
                        <div class="tit" v-else @click="loginWay = 1">{{ $t('M51') }}</div>
                        <div class="tits" @click="onforgetPassword">{{ $t('I5') }}</div>
                    </div>
                    <div class="btn">
                        <button class="submit-btn" :disabled="disabledLogin" @click="goLogins">{{ $t('I3') }}</button>
                        <div class="tit" style="margin-top: 20px;">{{ $t('M49') }}<span @click="onReg">{{ $t('M50')
                        }}</span></div>
                    </div>
                </span>
            </el-dialog>
        </div>
        <div class="tips">
            <el-dialog :title="$t('PH3')" :visible.sync="$store.state.showPass" width="440px" center top="30vh">
                <div class="info">
                    <div class="tabbars">
                        <div class="item" :class="passIN == index ? 'actss' : ''"
                            v-for="(item, index) in [$t('I33'), $t('I34')]" :key="index" @click="passinst(index)">
                            {{ item }}</div>
                    </div>
                    <div class="input-box" v-if="passIN == 0">
                        <el-input :placeholder="$t('I1')" v-model="forgetPass.phone"></el-input>
                    </div>
                    <div class="input-box" v-else>
                        <el-input :placeholder="$t('I20')" v-model="forgetPass.email"></el-input>
                    </div>
                    <div class="input-box input-code">
                        <div class="inputc">
                            <el-input :placeholder="$t('I17')" v-model="forgetPass.code"></el-input>
                        </div>
                        <div class="code" v-if="miao1" @click="getCode1">{{ $t('I18') }}</div>
                        <div class="code" v-else>{{ time1 }}s{{ $t('I19') }}</div>
                    </div>
                    <div class="input-box">
                        <el-input :placeholder="$t('M52')" show-password v-model="forgetPass.password"></el-input>
                    </div>
                    <div class="input-box">
                        <el-input :placeholder="$t('M53')" show-password v-model="forgetPass.newpassword"></el-input>
                    </div>
                </div>
                <span slot="footer">
                    <div class="btn">
                        <button class="submit-btn" :disabled="disabledResetPass" @click="goResetPass">{{ $t('M54')
                        }}</button>
                    </div>
                </span>
            </el-dialog>
        </div>
        <div class="tips">
            <el-dialog :title="$t('M55')" :visible.sync="$store.state.showRegister" width="440px" center top="25vh">
                <div class="info">
                    <div class="tabbars">
                        <div class="item" :class="rIN == index ? 'actss' : ''" v-for="(item, index) in [$t('I7'), $t('I8')]"
                            :key="index" @click="rinst(index)">{{ item }}
                        </div>
                    </div>
                    <div class="input-box" v-if="rIN == 0">
                        <el-input :placeholder="$t('I1')" v-model="resgister.phone"></el-input>
                    </div>
                    <div class="input-box" v-else>
                        <el-input :placeholder="$t('I20')" v-model="resgister.email"></el-input>
                    </div>
                    <div class="input-box input-code">
                        <div class="inputc">
                            <el-input :placeholder="$t('I17')" v-model="resgister.code"></el-input>
                        </div>
                        <div class="code" v-if="miao2" @click="getCode2">{{ $t('I18') }}</div>
                        <div class="code" v-else>{{ time2 }}s{{ $t('I19') }}</div>
                    </div>
                    <div class="input-box">
                        <el-input :placeholder="$t('M52')" show-password v-model="resgister.password"></el-input>
                    </div>
                    <div class="input-box">
                        <el-input :placeholder="$t('M53')" show-password v-model="resgister.newpassword"></el-input>
                    </div>

                </div>
                <span slot="footer">
                    <div class="btn">
                        <button class="submit-btn" :disabled="disabledResgister" @click="goNext">{{ $t('I25') }}</button>
                    </div>
                </span>
            </el-dialog>
        </div>
        <div class="tips">
            <el-dialog :title="$t('PH4')" :visible.sync="$store.state.showDatum" width="540px" center top="5vh">
                <div class="input-box">
                    <el-input :placeholder="$t('I16') + $t('I11')" v-model="datums.enterpriseName"></el-input>
                </div>
                <div class="boxf">
                    <div class="input-box">
                        <el-input :placeholder="$t('I16') + $t('I12')" v-model="datums.contactName"></el-input>
                    </div>
                    <div class="input-box">
                        <el-input :placeholder="$t('I16') + $t('info4')" v-model="datums.contactMobile"></el-input>
                    </div>
                </div>
                <div class="boxf">
                    <div class="input-box">
                        <el-input :placeholder="$t('I16') + $t('I13')" v-model="datums.contactEmail"></el-input>
                    </div>
                    <div class="input-box">
                        <el-input :placeholder="$t('I16') + $t('I14')" v-model="datums.enterpriseAddress"></el-input>
                    </div>
                </div>
                <div class="input-box">
                    <el-input :placeholder="$t('I16') + $t('I26')" v-model="datums.detailAddress"></el-input>
                </div>
                <div class="boxf">
                    <div class="input-box">
                        <el-input :placeholder="$t('I16') + $t('I27')" v-model="datums.openBank"></el-input>
                    </div>
                    <div class="input-box">
                        <el-input :placeholder="$t('I16') + $t('I28')" v-model="datums.payee"></el-input>
                    </div>
                </div>
                <div class="input-box">
                    <el-input :placeholder="$t('I16') + $t('I28')" v-model="datums.businessLicenseNo"></el-input>
                </div>
                <div class="input-box">
                    <el-input :placeholder="$t('I16') + $t('I333')" v-model="datums.bankCardNumber"></el-input>
                </div>
                <div class="input-box">
                    <el-input :placeholder="$t('I16') + $t('I30')" v-model="datums.enterpriseOperateRange"></el-input>
                </div>
                <el-upload class="avatar-uploader" :action="httpUrl + '/fangshan/dm-user/uploadAppOSS'"
                    :show-file-list="false" :on-success="success" :before-upload="beforeAvatarUpload" :on-error="error">
                    <img v-if="datums.businessLicense" :src="datums.businessLicense" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <p style="margin-left: 24px; margin-top: 10px; font-size: 17px;">{{ $t('I31') }}</p>
                <span slot="footer">
                    <div class="btn">
                        <button class="submit-btn" :disabled="disabledDet" @click="goSubmit">{{ $t('M56') }}</button>
                    </div>
                </span>
            </el-dialog>
        </div>
        <el-dialog title="提示" :visible.sync="isEdit" width="400px" center>
            <span style="font-size: 18px; text-align: center;!important">您确定要退出该账号吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onExit">确 定</el-button>
                <el-button @click="isEdit = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>


<script>
import { getEnterpriseUserExtInfo } from '@/api/mine'
import httpUrl from '@/plugins/axios/url'
import { appLogin, sendAccountCode, forgotPassword, checkIdentifyCode, register } from '@/api/loginAbout'
import { Message } from 'element-ui'
export default {
    name: 'VHeader',
    data() {
        return {
            info: {},
            httpUrl: httpUrl.baseUrl,
            datum: true,
            isLogin: false, //是否登录
            isEdit: false,
            value: "zh",
            options: [
                {
                    value: "zh",
                    label: "中文",
                },
                {
                    value: "en",
                    label: "English",
                },
            ],
            loginWay: 1,  //登录方式：1手机号2邮箱
            login: {
                phone: "",
                password: "",
                email: ''
            },
            forgetPass: {
                email: '',
                phone: "",
                code: "",
                password: "",
                newpassword: "",
            },
            resgister: {
                phone: "",
                code: "",
                password: "",
                newpassword: "",
                email: ""
            },
            datums: {
                enterpriseName: '', //企业名称
                contactName: '', //联系人姓名
                contactMobile: '', //联系人电话
                contactEmail: '', //联系人邮箱
                enterpriseAddress: '', //企业所在地
                detailAddress: '', //详细地址
                openBank: '', //开户行
                payee: '', //收款人
                bankCardNumber: '', //银行卡号
                enterpriseOperateRange: '', //企业经营范围
                businessLicense: '', //营业执照图片
                businessLicenseNo: '', //营业执照号码
            },
            passList: [this.$t('I33'), this.$t('I34')],
            rList: [this.$t('I7'), this.$t('I8')],
            passIN: 0, //找回密码方式:0手机号1邮箱
            rIN: 0, //0手机注册 1邮箱注册
            miao1: true, //发送验证码1状态
            time1: 60,
            miao2: true, //发送验证码1状态
            time2: 60,
        }
    },
    created() {
        if (localStorage.getItem('token')) {
            this.getInfo()
        }
        if (localStorage.getItem('language')) {
            this.$i18n.locale = localStorage.getItem('language');
            this.value = localStorage.getItem('language');
        }
    },
    mounted() {
        if (localStorage.getItem('token')) {
            this.isLogin = true
        }
        this.active = sessionStorage.getItem('ACTIVE_MENU');
    },
    computed: {
        disabledLogin() {
            if (this.loginWay == 1) {
                if (this.login.phone == '' || this.login.password == '') {
                    return true
                } else {
                    return false
                }

            } else {
                if (this.login.email == '' || this.login.password == '') {
                    return true
                } else {
                    return false
                }
            }
        },
        disabledResetPass() {
            if (this.passIN == 0) {
                if (this.forgetPass.phone == '' || this.forgetPass.code == '' || this.forgetPass.password == '' || this.forgetPass.newpassword == '') {
                    return true
                } else {
                    return false
                }
            } else {
                if (this.forgetPass.email == '' || this.forgetPass.code == '' || this.forgetPass.password == '' || this.forgetPass.newpassword == '') {
                    return true
                } else {
                    return false
                }
            }
        },
        disabledResgister() {
            if (this.rIN == 0) {
                if (this.resgister.phone == '' || this.resgister.code == '' || this.resgister.password == '' || this.resgister.newpassword == '') {
                    return true
                } else {
                    return false
                }
            } else {
                if (this.resgister.email == '' || this.resgister.code == '' || this.resgister.password == '' || this.resgister.newpassword == '') {
                    return true
                } else {
                    return false
                }
            }
        },
        disabledDet() {
            for (let key in this.datums) {
                if (this.datums[key] === '') {
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        onReg() {
            this.$store.commit('NOSHOWLOGIN')
            this.$store.commit('SHOWREGISTER')

        },
        onHome() {
            window.location.href = "/"
        },
        getInfo() {
            getEnterpriseUserExtInfo().then(res => {
                if (res.code === 200) {
                    this.info = res.data
                }
            })
        },
        //从登录跳转到忘记密码
        onforgetPassword() {
            this.$store.commit('NOSHOWLOGIN')
            this.$store.commit('SHOWPASS')
        },
        goOrder() {
            if (this.$route.path != '/centre/order') this.$router.push('/centre/order')
        },
        goLogins() {
            if (this.loginWay == 1) {
                appLogin({
                    mobile: this.login.phone,
                    password: this.login.password,
                    registerType: 1
                }).then(res => {
                    if (res.code === 200) {
                        if (this.loginWay == 1) {
                            localStorage.setItem('token', res.data)
                            this.isLogin = true
                            this.$store.commit('NOSHOWLOGIN')
                            this.getInfo()
                            this.$message.success(res.msg)
                            this.login.phone = ""
                            this.login.password = ""
                        } else {

                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else {
                appLogin({
                    email: this.login.email,
                    password: this.login.password,
                    registerType: 1
                }).then(res => {
                    if (res.code === 200) {
                        if (this.loginWay == 1) {
                            localStorage.setItem('token', res.data)
                            this.isLogin = true
                            this.$store.commit('NOSHOWLOGIN')
                            this.getInfo()
                            this.$message.success(res.msg)
                            this.login.email = ""
                            this.login.password = ""
                        } else {

                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        passinst(e) {
            this.passIN = e
        },
        rinst(e) {
            this.rIN = e
        },
        change(e) {
            this.$nextTick(() => {
                this.$i18n.locale = e;
                localStorage.setItem('language', e)
                location.reload();
            });
        },
        getCode1() {  //重置密码时获取验证码
            if (this.passIN == 0) {
                if (/^1[3-9]{1}[0-9]{9}$/.test(this.forgetPass.phone)) {
                    sendAccountCode({
                        mobile: this.forgetPass.phone,
                        registerType: 1
                    }).then(res => {
                        if (res.code === 200) {
                            if (res.code === 200) {
                                this.miao1 = false
                                Message({
                                    type: 'success',
                                    message: res.msg
                                })
                                let timer = setInterval(() => {
                                    if (this.time1 > 0) {
                                        this.time1--
                                    } else {
                                        this.miao1 = true
                                        this.time1 = 60
                                        clearInterval(timer)
                                    }
                                }, 1000)
                            } else {
                                Message({
                                    type: 'error',
                                    message: res.msg
                                })
                            }
                        }
                    })
                } else {
                    Message({
                        type: 'warning',
                        message: this.$t('M57')
                    })
                }
            } else {
                if (/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.forgetPass.email)) {
                    //接口
                    sendAccountCode({
                        email: this.forgetPass.email,
                        registerType: 2
                    }).then(res => {
                        if (res.code === 200) {
                            this.miao1 = false
                            Message({
                                type: 'success',
                                message: res.msg
                            })
                            let timer = setInterval(() => {
                                if (this.time1 > 0) {
                                    this.time1--
                                } else {
                                    this.miao1 = true
                                    this.time1 = 60
                                    clearInterval(timer)
                                }
                            }, 1000)
                        } else {
                            Message({
                                type: 'error',
                                message: res.msg
                            })
                        }
                    })
                } else {
                    Message({
                        type: 'warning',
                        message: this.$t('M58')
                    })
                }
            }
        },
        getCode2() {  //注册时获取验证码
            if (this.rIN == 0) {
                if (/^1[3-9]{1}[0-9]{9}$/.test(this.resgister.phone)) {
                    //接口
                    sendAccountCode({
                        mobile: this.resgister.phone,
                        registerType: 1
                    }).then(res => {
                        if (res.code === 200) {
                            this.miao2 = false
                            Message({
                                type: 'success',
                                message: res.msg
                            })
                            let timer = setInterval(() => {
                                if (this.time2 > 0) {
                                    this.time2--
                                } else {
                                    this.miao2 = true
                                    this.time2 = 60
                                    clearInterval(timer)
                                }
                            }, 1000)
                        } else {
                            Message({
                                type: 'error',
                                message: res.msg
                            })
                        }
                    })

                } else {
                    Message({
                        type: 'warning',
                        message: this.$t('M57')
                    })
                }
            } else {
                if (/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.resgister.email)) {
                    //接口
                    sendAccountCode({
                        email: this.resgister.email,
                        registerType: 2
                    }).then(res => {
                        if (res.code === 200) {
                            this.miao2 = false
                            Message({
                                type: 'success',
                                message: res.msg
                            })
                            let timer = setInterval(() => {
                                if (this.time2 > 0) {
                                    this.time2--
                                } else {
                                    this.miao2 = true
                                    this.time2 = 60
                                    clearInterval(timer)
                                }
                            }, 1000)
                        } else {
                            Message({
                                type: 'error',
                                message: res.msg
                            })
                        }
                    })
                } else {
                    Message({
                        type: 'warning',
                        message: this.$t('M58')
                    })
                }
            }
        },
        goResetPass() { //重置密码
            if (this.forgetPass.password != this.forgetPass.newpassword) {
                this.$message.warning(this.$t('M59'))
                return
            }
            console.log(this.passIN)
            if (this.passIN == 0) {
                forgotPassword({
                    mobile: this.forgetPass.phone,
                    password: this.forgetPass.password,
                    identifyCode: this.forgetPass.code,
                    registerType: 1
                }).then(res => {
                    if (res.code === 200) {
                        this.$store.commit('NOSHOWPASS')
                        this.forgetPass = {
                            email: "",
                            phone: "",
                            code: "",
                            password: "",
                            newpassword: "",
                        }
                        Message({
                            type: 'success',
                            message: this.$t('M60')
                        })
                        setTimeout(() => {
                            this.$store.commit('SHOWLOGIN')
                        }, 500);
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else {
                forgotPassword({
                    email: this.forgetPass.email,
                    password: this.forgetPass.password,
                    identifyCode: this.forgetPass.code,
                    registerType: 2
                }).then(res => {
                    if (res.code === 200) {
                        this.$store.commit('NOSHOWPASS')
                        this.forgetPass = {
                            email: "",
                            phone: "",
                            code: "",
                            password: "",
                            newpassword: "",
                        }
                        Message({
                            type: 'success',
                            message: this.$t('M60')
                        })
                        setTimeout(() => {
                            this.$store.commit('SHOWLOGIN')
                        }, 500);
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        onExit() { //退出账号
            localStorage.removeItem('token')
            this.isLogin = false
            Message({
                type: 'success',
                message: this.$t('M61')
            })
            this.isEdit = false
            window.location.href = "/"
        },
        goNext() {
            if (this.resgister.password != this.resgister.newpassword) {
                this.$message.warning(this.$t('M59'))
                return
            }
            if (this.rIN == 0) {
                checkIdentifyCode({
                    mobile: this.resgister.phone,
                    identifyCode: this.resgister.code,
                    registerType: 1
                }).then(res => {
                    if (res.code === 200) {

                        this.$message.success(this.$t('M62'))
                        setTimeout(() => {
                            this.$store.commit('NOSHOWREGISTER')
                            this.$store.commit('SHOWDATUM')
                        }, 500);

                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else {
                checkIdentifyCode({
                    email: this.resgister.email,
                    identifyCode: this.resgister.code,
                    registerType: 2
                }).then(res => {
                    if (res.code === 200) {
                        this.$message.success(this.$t('M62'))
                        setTimeout(() => {
                            this.$store.commit('NOSHOWREGISTER')
                            this.$store.commit('SHOWDATUM')
                        }, 500);
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
            //测试代码
            // if (this.rIN == 0) {
            //     this.resgister.phone = '18293824418'
            //     this.resgister.password = '666666'
            //     this.$store.commit('NOSHOWREGISTER')
            //     this.$store.commit('SHOWDATUM')
            //     this.$message.success('验证通过')
            // } else {
            //     this.resgister.email = '2689682113@qq.com'
            //     this.resgister.password = '666666'
            //     this.$store.commit('NOSHOWREGISTER')
            //     this.$store.commit('SHOWDATUM')
            //     this.$message.success('验证通过')
            // }
        },
        goSubmit() {
            if (this.rIN == 0) {
                let objs = {
                    enterpriseName: this.datums.enterpriseName,
                    contactName: this.datums.contactName,
                    contactMobile: this.datums.contactMobile,
                    contactEmail: this.datums.contactEmail,
                    enterpriseAddress: this.datums.enterpriseAddress,
                    detailAddress: this.datums.detailAddress,
                    openBank: this.datums.openBank,
                    payee: this.datums.payee,
                    bankCardNumber: this.datums.bankCardNumber,
                    enterpriseOperateRange: this.datums.enterpriseOperateRange,
                    businessLicense: this.datums.businessLicense,
                    businessLicenseNo: this.datums.businessLicenseNo,
                    registerType: 1,
                    mobile: this.resgister.phone,
                    password: this.resgister.password
                }
                register(objs).then(res => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        this.$store.commit('NOSHOWDATUM')
                        this.datums = {
                            enterpriseName: '',
                            contactName: '',
                            contactMobile: '',
                            contactEmail: '',
                            enterpriseAddress: '',
                            detailAddress: '',
                            openBank: '',
                            payee: '',
                            bankCardNumber: '',
                            enterpriseOperateRange: '',
                            businessLicense: '',
                            businessLicenseNo: '',
                        }
                        this.resgister.phone = ''
                        this.resgister.password = ''
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            } else {
                let objs = {
                    enterpriseName: this.datums.enterpriseName,
                    contactName: this.datums.contactName,
                    contactMobile: this.datums.contactMobile,
                    contactEmail: this.datums.contactEmail,
                    enterpriseAddress: this.datums.enterpriseAddress,
                    detailAddress: this.datums.detailAddress,
                    openBank: this.datums.openBank,
                    payee: this.datums.payee,
                    bankCardNumber: this.datums.bankCardNumber,
                    enterpriseOperateRange: this.datums.enterpriseOperateRange,
                    businessLicense: this.datums.businessLicense,
                    businessLicenseNo: this.datums.businessLicenseNo,
                    registerType: 2,
                    email: this.resgister.email,
                    password: this.resgister.password
                }
                register(objs).then(res => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        this.$store.commit('NOSHOWDATUM')
                        this.datums = {
                            enterpriseName: '',
                            contactName: '',
                            contactMobile: '',
                            contactEmail: '',
                            enterpriseAddress: '',
                            detailAddress: '',
                            openBank: '',
                            payee: '',
                            bankCardNumber: '',
                            enterpriseOperateRange: '',
                            businessLicense: '',
                            businessLicenseNo: '',
                        }
                        this.resgister.phone = ''
                        this.resgister.password = ''
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            }
        },
        //上传成功
        success(response) {
            this.$message.success(this.$t('M63'))
            this.datums.businessLicense = response.data.url
            // console.log(response.data.url)
        },
        //上传后的钩子，限制大小
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error(this.$t('M64'));
            }
            return isLt2M;
        },
        //上传失败
        error(err, file) {
            this.$message.error(this.$t('M65'));
        },
    }
}
</script>


<style lang="scss" scoped>
::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 5%;
    overflow: hidden;
}

.avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 110px;
    height: 110px;
    line-height: 110px;
    text-align: center;
}

.avatar {
    width: 110px;
    height: 110px;
    display: block;
}

::v-deep .el-input__inner {
    background: #f3f3f3;
    height: 45px;
}

.header {
    width: 100%;
    height: 98px;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
        display: flex;
        align-items: center;
        margin-left: 90px;
        font-size: 22px;
        font-weight: bold;
        cursor: pointer;
        user-select: none;

        .logo {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            margin-right: 20px;
        }
    }

    .left {
        display: flex;
        align-items: center;
        font-size: 20px;
        margin-right: 90px;

        div {
            margin: 0 30px;
            cursor: pointer;
            user-select: none;
        }

        .el-select {
            width: 100px;
        }
    }

}

.tips {
    ::v-deep .el-dialog {
        border-radius: 15px;
    }

    ::v-deep .el-dialog__body {
        padding: 0px 25px 0px
    }

    ::v-deep .el-dialog__header {
        padding: 42px 20px 28px;

        .el-dialog__title {
            font-size: 22px;
        }

    }

    .boxf {
        display: flex;
        justify-content: space-between;

        .input-box {
            .el-input {
                width: 100%;
            }
        }
    }

    .input-box {
        margin-bottom: 20px;
    }

    .info {
        position: relative;
        border-top: 1px solid #d1d1d1;
        padding: 20px;

        &::before {
            content: '';
            position: absolute;
            top: -3px;
            width: 100px;
            height: 3px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #222;
        }

        .tabbars {
            display: flex;
            text-align: center;
            justify-content: center;

            .item {
                width: 160px;
                height: 38px;
                border: 1px solid #0D5E6B;
                line-height: 38px;
                cursor: pointer;
                user-select: none;
                color: #0D5E6B;
            }

            .actss {
                color: #FFF;
                background: #0D5E6B;
            }
        }

        .input-code {
            display: flex;
            align-items: center;
            gap: 20px;

            .inputc {
                width: 70%;
            }

            .code {
                cursor: pointer;
                user-select: none;
                text-align: center;
                color: #0D5E6B;
            }
        }

        .input-box {
            background: #F3F3F3;
            border-radius: 4px;
            margin-top: 22px;

            ::v-deep .el-input__inner {
                background: #f3f3f3;
                height: 45px;
            }
        }
    }

    .title {
        font-size: 15px;
        font-weight: 400;
        color: #878383;
        cursor: pointer;
        user-select: none;
    }

    .bottom {
        color: black;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
    }

    .tit {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
        user-select: none;

        span {
            color: #0d5e6b;
            cursor: pointer;
            user-select: none;
        }
    }

    .tits {
        color: #878383;
        font-size: 16px;
        cursor: pointer;
        user-select: none;

    }

    .btn {
        padding: 20px 30px;
    }
}
</style>