<template>
    <div class="centers box">
        <v-title>{{ $t('M29') }}</v-title>
        <div class="info-box f">
            <div class="info"> {{ $t('M30') }}：{{ from.setOutAddress }}</div>
            <div class="info" v-if="type == 0"> {{ $t('M31') }}：{{ from.arriveOutAddress }}</div>
        </div>
        <div class="info-box f">
            <div class="info"> {{ $t('M32') }}：{{ from.appointmentTime }}</div>
        </div>
        <div class="info-box f">
            <div class="info">{{ $t('M34') }}：{{ from.numberOfPassengers }}</div>
        </div>
        <div class="info-box f" v-if="type == 0">
            <div class="info">{{ $t('M33') }}：{{ from.travelDistance }}KM</div>
        </div>
        <div class="info-box f" v-else>
            <div class="info">{{ $t('M35') }}：{{ from.vehicleDuration }}H</div>
        </div>
        <v-gap></v-gap>
        <v-title>{{ $t('info1') }}</v-title>
        <div class="carlist">
            <div class="item" v-for="item in carList" :key='item.id'>
                <div class="right">
                    <img :src="item.coverImage" alt="">
                </div>
                <div class="left">
                    <div class="name">{{ item.carTypeName }}</div>
                    <div class="infos">{{ item.contentValidity }}</div>
                    <div class="serve">
                        <div class="ins">
                            <img src="../../assets/iconImg/icon_renshu.png" alt="">
                            <div>{{ item.passengerCapacity }}</div>
                        </div>
                        <div class="ins">
                            <img src="../../assets/iconImg/icon_xingl.png" alt="">
                            <div>{{ item.luggageBearCapacity }}kg</div>
                        </div>
                        <div class="ins" v-if="item.customerServiceOrNot">
                            <img src="../../assets/iconImg/icon_24.png" alt="">
                            <div>24h客服</div>
                        </div>
                    </div>
                    <div class="price"><span>￥{{ item.unitPrice }}</span> ×{{ item.num }}</div>
                </div>
            </div>
        </div>
        <v-gap></v-gap>
        <v-title>{{ $t('info6') }}</v-title>
        <div class="serve" style="margin-left: 14px; margin-top: 20px;">
            <div class="ins" v-for="item in severList" :key="item.idd">
                <img src="../../assets/iconImg/icon_fj.png" v-if="item.serviceName == '接机服务'">
                <img src="../../assets/iconImg/icon_chend.png" v-if="item.serviceName == '儿童椅'">
                <img src="../../assets/iconImg/icon_qit.png" v-if="item.serviceName != '儿童椅' && item.serviceName != '接机服务'">

                <div v-if="item.serviceName == '儿童椅'">{{ item.serviceName }} × {{ item.num }} <span>￥{{ item.servicePrice *
                    item.num }}</span></div>
                <div v-else>{{ item.serviceName }} <span>￥{{ item.servicePrice * item.num }}</span></div>
            </div>
        </div>
        <v-gap></v-gap>
        <v-gap></v-gap>
        <v-title>{{ $t('PH9') }}</v-title>
        <div class="info-box ff adds" @click="AddPerson" v-if="!showInfo">
            <i style="font-size: 24px; color: #0D5E6B;" class="el-icon-circle-plus-outline"></i>
            <div class="info">{{ $t('PH20') }}</div>
        </div>
        <div class="info-box f" v-if="showInfo">
            <div class="info"> {{ personss.Sname }}{{ personss.name }}</div>
            <div class="ins ff">
                <i class="el-icon-phone" style="font-size: 18px; color: #0D5E6B;"></i>
                <div style="font-size: 16px;">{{ personss.select3 }} {{ personss.phone }}</div>
            </div>
            <div class="ins ff">
                <i class="el-icon-message" style="font-size: 18px;color: #0D5E6B;"></i>
                <div style="font-size: 16px;">{{ personss.email }}</div>
            </div>
            <div class="ins ff sss" @click="personEdit">
                <i class="el-icon-edit" style="font-size: 20px;color: #0D5E6B;"></i>
            </div>
            <div class="ins ff sss" @click="persondel">
                <i class="el-icon-delete-solid" style="font-size: 20px;color: #444;"></i>
            </div>
        </div>
        <v-gap></v-gap>
        <v-title>{{ $t('info10') }}</v-title>
        <div class="info-box">
            <div class="info" style="display: flex; align-items: center; gap: 20px;">{{ $t('info10') }}:
                <div style="width: 40%;">
                    <el-input :placeholder="$t('I16') + $t('info10')" v-model="fightNum"></el-input>
                </div>
            </div>
        </div>
        <v-gap></v-gap>
        <v-title>{{ $t('info19') }}</v-title>
        <div class="info-box">
            <div class="info" style="display: flex; align-items: center; gap: 20px;">
                <div style="width: 80%;">
                    <el-input :placeholder="$t('M36')" v-model="orderLeaveMessage"></el-input>
                </div>
            </div>
        </div>
        <v-gap></v-gap>
        <v-title>{{ $t('info20') }}</v-title>
        <div class="info-box f">
            <div class="info">{{ $t('M37') }}</div>
            <el-radio-group v-model="radio1">
                <el-radio :label="1">{{ $t('M38') }}</el-radio>
                <el-radio :label="0">{{ $t('M39') }}</el-radio>
            </el-radio-group>
        </div>
        <v-gap></v-gap>
        <v-title>{{ $t('info21') }}</v-title>
        <div class="info-box f">
            <div class="info">{{ $t('info21') }}</div>
            <el-radio-group v-model="radio2">
                <el-radio :label="1">{{ $t('M40') }}</el-radio>
            </el-radio-group>
        </div>
        <div class="btns">
            <button class="submit-btn" @click="submit">{{ $t('M42') }}</button>
        </div>
        <div class="tips">
            <el-dialog :title="$t('PH9')" :visible.sync="showPerson" width="440px" center top="25vh">
                <div class="info">
                    <div class="input-box">
                        <el-input :placeholder="$t('I16') + $t('M74')" v-model="person.Sname"></el-input>
                    </div>
                    <div class="input-box">
                        <el-input :placeholder="$t('I16') + $t('M75')" v-model="person.name"></el-input>
                    </div>
                    <div class="input-box">
                        <el-input :placeholder="$t('I16') + $t('M76')" v-model="person.email"></el-input>
                    </div>
                    <div class="input-box">
                        <el-input :placeholder="$t('I16') + $t('M77')" v-model="person.phone">
                            <el-select style="width: 80px;" v-model="person.select3" slot="prepend"
                                :placeholder="$t('I15')">
                                <el-option label=" +86" value="+86"></el-option>
                                <el-option label="+81" value="+81"></el-option>
                                <el-option label="+82" value="+82"></el-option>
                            </el-select>
                        </el-input>
                    </div>
                </div>
                <span slot="footer">
                    <div class="btn">
                        <button class="submit-btn" :disabled="disabledResgister" @click="goNext">{{ $t('I38') }}</button>
                    </div>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { orderGeneration, charteredBusOrderGeneration } from '@/api/home'
export default {
    name: 'confirm',
    data() {
        return {
            type: 0,  //订单类型:0专车1包车
            from: {},
            radio1: 1,
            radio2: 1,
            showPerson: false,
            showInfo: false, //显示负责人信息
            person: {
                Sname: '',
                name: '',
                email: '',
                phone: '',
                select3: '+86',
            },
            personss: {}, //页面显示和接口上传的负责人信息

            theBaby: 0, //宝宝座椅数量
            theRaising: 1, //接机服务（1不需要 2需要）
            dmEnterpriseOrderCarBindDtoList: [], //车辆信息
            otherServiceIds: '', //其他服务主键（多个主键用逗号拼接）
            fightNum: '',
            orderLeaveMessage: ''

        }
    },
    computed: {
        disabledResgister() {
            for (let key in this.person) {
                if (this.person[key] === '') {
                    return true;
                }
            }
            return false;
        }
    },
    created() {
        this.type = this.$route.query.type
        this.from = JSON.parse(decodeURIComponent(this.$route.query.from))
        this.carList = JSON.parse(decodeURIComponent(this.$route.query.carList))
        this.severList = JSON.parse(decodeURIComponent(this.$route.query.severList))

        let a = this.severList.findIndex(i => i.serviceName == "接机服务")
        if (a != -1) {
            this.theRaising = 2
        } else {
            this.theRaising = 1
        }
        let b = this.severList.findIndex(i => i.serviceName == "儿童椅")
        let arr = []
        arr = this.severList.filter(i => {
            return i.serviceName == "儿童椅"
        })
        if (b != -1) {
            this.theBaby = arr[0].num
        } else {
            this.theBaby = 0
        }
        let arr2 = []
        arr2 = this.severList.filter(i => {
            return i.id != null
        })
        let arr3 = []
        arr2.forEach(i => {
            arr3.push(i.id)
        })
        this.otherServiceIds = arr3.join(',')
        this.carList.forEach(i => {
            this.dmEnterpriseOrderCarBindDtoList.push({
                refEnterpriseCarId: i.id,
                carNum: i.num
            })
        })
        console.log(this.type)
        console.log(this.from)
        console.log(this.carList)
        console.log(this.severList)
    },
    methods: {
        AddPerson() {
            this.showPerson = true
        },
        goNext() {
            this.showPerson = false
            this.showInfo = true
            this.personss = JSON.parse(JSON.stringify(this.person))
        },
        persondel() {
            this.showInfo = false
            this.person = {
                Sname: '',
                name: '',
                email: '',
                phone: '',
                select3: '+86',
            }
        },
        personEdit() {
            this.showPerson = true
        },
        submit() {
            if (!localStorage.getItem('token')) {
                this.$message.warning('您必须登录后才能创建订单哦！')
                setTimeout(() => {
                    this.$store.commit('SHOWLOGIN')
                }, 1000);
                return
            }
            if (!this.showInfo) {
                this.$message.warning('请添加负责人')
                return
            }
            if (this.fightNum == '') {
                this.$message.warning('请填写航班号码')
                return
            }
            let data = {
                orderSource: 'PC',
                ...this.from,
                theRaising: this.theRaising,
                theBaby: this.theBaby,
                authorityEmailOrNot: this.radio1,
                payWay: this.radio2,
                dmEnterpriseOrderCarBindDtoList: this.dmEnterpriseOrderCarBindDtoList,
                otherServiceIds: this.otherServiceIds,
                travelDistance: Number(this.from.travelDistance),
                numberOfPassengers: Number(this.from.numberOfPassengers),
                directorName: this.personss.name,
                directorSurname: this.personss.Sname,
                directorEmail: this.personss.email,
                directorMobile: this.personss.select3 + ' ' + this.personss.phone,
                airplaneNo: this.fightNum,
                orderLeaveMessage: this.orderLeaveMessage
            }
            if (this.type == 0) {
                orderGeneration(data).then(res => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        setTimeout(() => {
                            this.$router.push('/centre/order')
                        }, 500);
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            } else {
                charteredBusOrderGeneration(data).then(res => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        setTimeout(() => {
                            this.$router.push('/centre/order')
                        }, 500);
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            }
            console.log(data)
        }
    }
}
</script>

<style lang="scss" scoped>
.sss {
    cursor: pointer;
    user-select: none;
}

.adds {
    cursor: pointer;
    user-select: none;
}

::v-deep .el-input__inner {
    background: #f3f3f3;
    height: 45px;
}

::v-deep .el-radio__label {
    font-size: 16px;
}

.box {
    background: #fff;
    // height: calc(100vh - 138px);
    // height: 1000px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 12px;
    padding: 20px;
}

.f {
    display: flex;
    gap: 50px;
}

.serve {
    display: flex;
    gap: 20px 50px;
    flex-wrap: wrap;
}

.ins {
    display: flex;
    gap: 4px;
    align-items: center;

    img {
        width: 18px;
        height: 18px;
    }

    font-size: 16px;

    span {
        color: #ff0909;
    }
}

.ff {
    display: flex;
    gap: 4px;
    align-items: center;
}

.info-box {
    margin: 14px 0 14px 14px;

    .info {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
    }
}

.carlist {
    // margin-bottom: 24px;
    margin: 0px 0 20px 14px;

    .item {
        margin-top: 18px;
        display: flex;
        gap: 20px;

        .right {
            img {
                width: 152px;
                height: 118px;
                object-fit: cover;
            }
        }

        .left {
            padding: 4px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .name {
                font-size: 18px;
                font-weight: 400;
                color: #000000;
            }

            .infos {
                font-size: 16px;
                font-weight: 400;
                color: #808080;
                line-height: 20px;
            }

            .price {
                font-size: 20px;

                span {
                    color: #ff0909;
                }
            }
        }
    }
}

.btns {
    margin-top: 28px;
    width: 24%;
}

.tips {
    ::v-deep .el-dialog {
        border-radius: 15px;
    }

    ::v-deep .el-dialog__body {
        padding: 0px 25px 0px
    }

    ::v-deep .el-dialog__header {
        padding: 42px 20px 28px;

        .el-dialog__title {
            font-size: 22px;
        }

    }

    .boxf {
        display: flex;
        justify-content: space-between;

        .input-box {
            .el-input {
                width: 100%;
            }
        }
    }

    .input-box {
        margin-bottom: 20px;
    }

    .info {
        position: relative;
        border-top: 1px solid #d1d1d1;
        padding: 20px;

        &::before {
            content: '';
            position: absolute;
            top: -3px;
            width: 100px;
            height: 3px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #222;
        }

        .tabbars {
            display: flex;
            text-align: center;
            justify-content: center;

            .item {
                width: 160px;
                height: 38px;
                border: 1px solid #0D5E6B;
                line-height: 38px;
                cursor: pointer;
                user-select: none;
                color: #0D5E6B;
            }

            .actss {
                color: #FFF;
                background: #0D5E6B;
            }
        }

        .input-code {
            display: flex;
            align-items: center;
            gap: 20px;

            .inputc {
                width: 70%;
            }

            .code {
                cursor: pointer;
                user-select: none;
                text-align: center;
                color: #0D5E6B;
            }
        }

        .input-box {
            background: #F3F3F3;
            border-radius: 4px;
            margin-top: 22px;

            ::v-deep .el-input__inner {
                background: #f3f3f3;
                height: 45px;
            }
        }
    }

    .title {
        font-size: 15px;
        font-weight: 400;
        color: #878383;
        cursor: pointer;
        user-select: none;
    }

    .bottom {
        color: black;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
    }

    .tit {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
        user-select: none;

        span {
            color: #0d5e6b;
            cursor: pointer;
            user-select: none;
        }
    }

    .tits {
        color: #878383;
        font-size: 16px;
        cursor: pointer;
        user-select: none;

    }

    .btn {
        padding: 20px 30px;
    }
}
</style>