<template>
    <div>
        <div class="head">
            <div class="centers" style="display: flex;align-items: center; justify-content: space-between;">
                <div class="userInfo">
                    <div class="header">
                        <img :src="info.businessLicense" width="100%" height="100%">
                    </div>
                    <div class="info">
                        <p class="nickname">{{ info.enterpriseName }}</p>
                        <p class="phone">{{ $t('my1') }}：{{ info.businessLicenseNo }}</p>
                        <p class="phone">{{ $t('my2') }}：{{ info.enterpriseAddress }}{{ info.detailAddress }} </p>
                    </div>
                </div>
                <div class="btn">
                    <button @click="onLink">{{ $t('my7') }}</button>
                </div>
            </div>
        </div>
        <v-gap></v-gap>
        <div class="centers" style="display: flex; gap: 10px;">
            <v-sidebar></v-sidebar>
            <div style="width: 100%;">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import { getEnterpriseUserExtInfo } from '@/api/mine'
export default {
    name: 'center',
    data() {
        return {
            info: {}
        }
    },
    created() {
        getEnterpriseUserExtInfo().then(res => {
            if (res.code === 200) {
                this.info = res.data
            }
        })

    },
    methods: {
        onSet() { },
        onLink() {
            if (this.$route.path != '/centre/Link') this.$router.push('/centre/Link')
        }
    }
}
</script>

<style lang="scss" scoped>
.head {
    width: 100%;
    height: 210px;
    background: #e7eced;
    padding-top: 50px;


    .userInfo {
        display: flex;
        align-items: center;
        gap: 20px;

        .header {
            width: 110px;
            height: 110px;
            position: relative;
            // border-radius: 50%;
            overflow: hidden;
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .nickname {
                font-size: 20px;
                font-weight: 400;
                color: #000000;
            }

            .phone {
                font-size: 15px;
                font-weight: 400;
                color: #727272;
            }

            .member {
                font-size: 15px;
                font-weight: 400;
                color: #000000;
            }
        }
    }

    .btn {
        button {
            width: 184px;
            height: 52px;
            background: #0D5E6B;
            color: #fff;
            font-size: 18px;
            cursor: pointer;
            user-select: none;
        }
    }
}
</style>