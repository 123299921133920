<template>
    <div class="box">
        <ul>
            <li @click="navBar(1, '/centre/order')" :class="{ 'active': $route.path == '/centre/order' }">
                {{ $t('PH5') }}
            </li>
            <li @click="navBar(2, '/centre/firm')" :class="{ 'active': $route.path == '/centre/firm' }">
                {{ $t('my4') }}
            </li>
            <li @click="navBar(3, '/centre/promotion')" :class="{ 'active': $route.path == '/centre/promotion' }">
                {{ $t('my5') }}
            </li>
            <li @click="navBar(4, '/centre/finance')" :class="{ 'active': $route.path == '/centre/finance' }">
                {{ $t('my6') }}
            </li>
            <li @click="navBar(7, '/centre/set')" :class="{ 'active': $route.path == '/centre/set' }">
                {{ $t('my8') }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: 1,
        }
    },
    mounted() {
        this.active = sessionStorage.getItem('ACTIVE_MENU_PERSON');
    },
    methods: {
        navBar(id, path) {
            this.active = id;
            sessionStorage.setItem('ACTIVE_MENU_PERSON', id);
            this.$router.push(path, () => { }, () => { })
        }
    }
}
</script>

<style scoped lang="scss">
.box {
    width: 148px;
    background: #FFFFFF;
    height: 302px;

    ul {
        padding: 14px 0;
    }

    li {
        padding-left: 30px;
        height: 55px;
        line-height: 55px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;

        .router-link-exact-active {
            color: #0D5E6B;
        }

        a {
            color: #000;
        }


    }

    .active {
        font-weight: 400;
        background: #0D5E6B08;
        border-left: 4px solid #09525e;
        padding-left: 26px;

    }
}
</style>