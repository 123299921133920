<template>
    <div class="item">
        <div class="top">
            <div class="left">
                <div class="on">{{ item.orderNo }}</div>
                <div class="type" v-if="item.orderType == 1">{{ $t('home.zhuanche') }}</div>
                <div class="type" v-if="item.orderType == 2">{{ $t('home.baoche') }}</div>
            </div>
            <div class="right">{{ item.createTime }}</div>
        </div>
        <div class="info">
            <div class="row">
                <div class="message">
                    <img src="../assets/iconImg/icon_start.png" alt="">
                    <div class="in">{{ item.setOutAddress }}</div>
                </div>
                <div class="message" v-if="item.orderType == 1">
                    <img src="../assets/iconImg/icon_start.png" alt="">
                    <div class="in">{{ item.arriveOutAddress }}</div>
                </div>
            </div>
            <div class="row">
                <div class="message">
                    <img src="../assets/iconImg/icon_getTime.png" alt="">
                    <div class="in">{{ item.appointmentTime }}</div>
                </div>
                <div class="message">
                    <img src="../assets/iconImg/icon_renshu.png" alt="">
                    <div class="in">{{ item.numberOfPassengers }}</div>
                </div>
                <div class="message" v-if="item.orderType == 2">
                    <img src="../assets/iconImg/icon_getTime.png" alt="">
                    <div class="in">{{ item.vehicleDuration }}H</div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="buttons">
                <div class="btn1" v-if="item.myOrderStatus == 1" @click.stop="onQuxiao(item.id)">{{ $t('info27') }}</div>
                <div class="btn2" v-if="item.myOrderStatus == 3" @click.stop="onPay(item.id)">{{ $t('D11') }}</div>
            </div>
            <div class="buttons" v-if="item.myOrderStatus == 4">
                <div class="btn2" @click.stop="onDel(item.id)">{{ $t('D13') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { cancelOrder, delOrder } from '@/api/home'
export default {
    name: 'VOrder',
    props: {
        item: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {

        }
    },
    methods: {
        onQuxiao(id) {
            if (confirm('您确定要取消该订单吗？')) {
                cancelOrder(id).then(res => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        this.$emit('refresh')
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            }
        },
        onPay(id) {
            console.log(id, '去支付')
        },
        onDel(id) {
            if (confirm('您确定要删除该订单吗？')) {
                delOrder(id).then(res => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        this.$emit('refresh')
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.item {
    padding: 18px 14px;
    width: 100%;
    // height: 100px;
    background: #fff;
    position: relative;
    margin-bottom: 14px;
    cursor: pointer;
    user-select: none;

    .top {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #999999;

        .left {
            display: flex;
            gap: 20px;
            align-items: center;

            .type {
                // width: 48px;
                padding: 0 6px;
                height: 22px;
                background: #FF4200;
                border-radius: 5px;
                color: #fff;
                text-align: center;
                line-height: 22px;
            }
        }

    }

    .row {
        display: flex;
        gap: 30px;
        margin-top: 14px;
    }

    .info {
        .message {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: #000000;

            img {
                width: 13px;
                height: 16px;
                margin-right: 4px;
            }
        }
    }

    .bottom {
        position: absolute;
        right: 10px;
        bottom: 10px;

        .btn1 {
            width: 100px;
            border: 1px solid #0D5E6B;
            text-align: center;
            color: #0D5E6B;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
            user-select: none;
        }

        .buttons {
            display: flex;
            gap: 20px;
        }

        .btn2 {
            width: 100px;
            border: 1px solid #0D5E6B;
            text-align: center;
            color: #fff;
            background: #0D5E6B;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
            user-select: none;
        }
    }
}
</style>