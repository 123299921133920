// 我的相关

import { $http } from '../plugins/axios'
const { post, get } = $http



//获取用户邮箱或者手机号：businessType获取类型（1：手机号，2：邮箱）
export const getUserMessage = (businessType) => {
    return get(`/api/system/enterpriseUser/getUserMessage?businessType=${businessType}`)
}
//更换手机号或者更换邮箱
export const replaceAccount = (params) => {
    return post('/api/system/enterpriseUser/replaceAccount', params)
}
//修改登录密码
export const replacePassword = (params) => {
    return post('/api/system/enterpriseUser/replacePassword', params)
}
//企业管理--获取企业信息
export const getEnterpriseUserExtInfo = () => {
    return get('/api/system/enterpriseUser/getEnterpriseUserExtInfo')
}
//企业管理--更改企业信息
export const editEnterpriseInfo = (params) => {
    return post('/api/system/enterpriseUser/editEnterpriseInfo', params)
}
//获取企业用户财务金额信息
export const getEnterpriseFinanceInfo = () => {
    return get('/api/system/enterpriseUser/getEnterpriseFinanceInfo')
}
//获取企业用户财务明细分页列表
export const pageAllFinancialDetails = (pageNum, pageSize) => {
    return get(`/api/system/enterpriseUser/pageAllFinancialDetails?pageNum=${pageNum}&pageSize=${pageSize}`)
}