<template>
    <div class="bg">
        <div class="card">
            <div class="cardNav">
                <div v-for="(item, index) in navList" :key="index" :class="{ active: index == isActive }"
                    @click="changeNav(index)">
                    {{ item }}
                </div>
            </div>
            <div class="card-form">
                <div class="input-box">
                    <el-input prefix-icon="el-icon-location-outline" :placeholder="$t('home.start')" v-model="start
                        "></el-input>
                </div>
                <div class="input-box" v-if="isActive == 0">
                    <el-input prefix-icon="el-icon-location-outline" :placeholder="$t('home.end')" v-model="end"></el-input>
                </div>
                <div class="input-box">
                    <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :placeholder="$t('home.date')"
                        v-model="Sdate">
                    </el-date-picker>
                </div>
                <div class="input-box">
                    <el-time-picker format="HH:mm:ss" value-format="HH:mm:ss" :placeholder="$t('home.time')"
                        v-model="Stime">
                    </el-time-picker>
                </div>
                <div class="input-box">
                    <el-input prefix-icon="el-icon-user" :placeholder="$t('home.fare')" v-model.number="fare"></el-input>
                </div>
                <div class="input-box" v-if="isActive == 1">
                    <el-select v-model="value" clearable :placeholder="$t('home.duration')">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-box">
                    <button class="submit-btn" @click="goChaxue">{{ this.$t('M43') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { listCharterTime, distanceCalculation } from '@/api/home'
export default {
    data() {
        return {
            isActive: 0,
            navList: [this.$t('home.zhuanche'), this.$t('home.baoche')],
            options: [],
            value: '',  //选中的用车时长
            start: '',  //出发地
            end: '',    //到达地
            Sdate: '',  //出发日期
            Stime: '',  //出发时间
            fare: '',   //乘客数量
            distance: '',
        }
    },
    created() {
        listCharterTime().then(res => {
            res.data.forEach(item => {
                item.value = item.rentalDuration
                item.label = item.rentalDuration + "H"
            })
            this.options = res.data
        })
    },
    methods: {
        changeNav(index) {
            this.isActive = index
        },
        goChaxue() {
            if (this.isActive == 0) {
                if (this.start.trim() === '') {
                    this.$message.warning(this.$t('M44'))
                    return
                }
                if (this.end.trim() === '') {
                    this.$message.warning(this.$t('M44'))

                    return
                }
                if (this.Sdate === '') {
                    this.$message.warning(this.$t('M44'))

                    return
                }
                if (this.Stime === '') {
                    this.$message.warning(this.$t('M44'))

                    return
                }
                if (this.fare === '') {
                    this.$message.warning(this.$t('M44'))

                    return
                }
                const loading = this.$loading({
                    lock: true,
                    text: '计算距离中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.8)'
                });
                distanceCalculation(this.start, this.end).then(res => {
                    if (res.code === 200) {
                        this.distance = res.msg
                        let type = 0
                        let from = {
                            setOutAddress: this.start,
                            arriveOutAddress: this.end,
                            appointmentTime: this.Sdate + ' ' + this.Stime,
                            numberOfPassengers: this.fare,
                            travelDistance: this.distance
                        }
                        this.$router.push({
                            path: '/carList', query: {
                                type,
                                from: encodeURIComponent(JSON.stringify(from)),
                            }
                        })
                        this.$message.success('计算成功')
                        loading.close();
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            } else {
                if (this.start.trim() === '') {
                    this.$message.warning(this.$t('M44'))
                    return
                }
                if (this.Sdate === '') {
                    this.$message.warning(this.$t('M44'))
                    return
                }
                if (this.Stime === '') {
                    this.$message.warning(this.$t('M44'))
                    return
                }
                if (this.fare === '') {
                    this.$message.warning(this.$t('M44'))
                    return
                }
                if (this.value === '') {
                    this.$message.warning(this.$t('M44'))
                    return
                }
                let type = 1
                let from = {
                    setOutAddress: this.start,
                    appointmentTime: this.Sdate + ' ' + this.Stime,
                    numberOfPassengers: this.fare,
                    vehicleDuration: this.value
                }
                this.$router.push({
                    path: '/carList', query: {
                        type,
                        from: encodeURIComponent(JSON.stringify(from)),
                    }
                })
            }
        }
    }
}
</script>


<style lang="scss" scoped>
.bg {
    height: calc(100vh - 98px);
    width: 100%;
    background-image: url('../../assets/images/bg_home.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .card {
        margin: auto;
        width: 540px;
        transform: translateY(40%);

        .cardNav {
            display: flex;
            justify-content: space-around;
            font-size: 20px;
            color: #fff;

            div {
                width: 160px;
                background-color: #c1c1c1;
                line-height: 60px;
                border-radius: 10px 10px 0 0;
                text-align: center;
                cursor: pointer;
                user-select: none;

                &.active {
                    background-color: #fff;
                    color: #0d5e6b;
                    font-weight: bold;
                }
            }
        }

        .card-form {
            background-color: #fff;
            border-radius: 20px;
            // height: 440px;
            padding: 26px 57px;
            box-sizing: border-box;

            .input-box {
                margin-bottom: 24px;
            }

            :last-child {
                margin-bottom: 0px;
            }

            ::v-deep .el-input__inner {
                background: #f3f3f3;
                height: 50px;
            }

            .el-date-editor.el-input {
                width: 100%;
            }

            .el-select {
                width: 100%;
            }
        }
    }
}
</style>