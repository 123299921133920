<template>
    <div class="centers box">
        <v-title>{{ $t('info1') }}</v-title>
        <div class="carList">
            <el-checkbox-group v-model="checkList" @change="change1">
                <div class="item" v-for="item in list" :key="item.id">
                    <el-checkbox :label="item.id"></el-checkbox>
                    <div class="info">
                        <div class="right">
                            <img :src="item.coverImage" alt="">
                        </div>
                        <div class="left">
                            <div class="name">{{ item.carTypeName }}</div>
                            <div class="infos">{{ item.contentValidity }}</div>
                            <div class="serve">
                                <div class="ins">
                                    <img src="../../assets/iconImg/icon_renshu.png" alt="">
                                    <div>{{ item.passengerCapacity }}</div>
                                </div>
                                <div class="ins">
                                    <img src="../../assets/iconImg/icon_xingl.png" alt="">
                                    <div>{{ item.luggageBearCapacity }}kg</div>
                                </div>
                                <div class="ins" v-if="item.customerServiceOrNot">
                                    <img src="../../assets/iconImg/icon_24.png" alt="">
                                    <div>24h客服</div>
                                </div>
                            </div>
                            <div class="price">
                                <span>￥{{ item.unitPrice }}</span>
                                <el-input-number v-model="item.num" :min="1"></el-input-number>
                            </div>
                        </div>
                    </div>
                </div>
            </el-checkbox-group>
        </div>
        <div class="paging">
            <el-pagination background layout="prev, pager, next" :total="count" :page-size="4"
                :page-count="Math.ceil(count / 3)" @current-change="currentChange">
            </el-pagination>
        </div>
        <v-gap></v-gap>
        <v-gap></v-gap>
        <v-gap></v-gap>
        <v-gap></v-gap>
        <v-title>{{ $t('info6') }}</v-title>
        <div class="serveList">
            <el-checkbox-group v-model="checkList2" @change="change2">
                <div class="items" v-for="item in serveList" :key="item.idd">
                    <el-checkbox :label="item.idd"></el-checkbox>
                    <div class="ins">
                        <img src="../../assets/iconImg/icon_fj.png" v-if="item.serviceName == '接机服务'">
                        <img src="../../assets/iconImg/icon_chend.png" v-if="item.serviceName == '儿童椅'">
                        <img src="../../assets/iconImg/icon_qit.png"
                            v-if="item.serviceName != '儿童椅' && item.serviceName != '接机服务'">
                        <div>{{ item.serviceName }} <span>￥{{ item.servicePrice * item.num }}</span></div>
                        <el-input-number v-model="item.num" :min="1" v-if="item.serviceName == '儿童椅'"></el-input-number>
                    </div>
                </div>
            </el-checkbox-group>
        </div>
        <div class="btnbox">
            <button class="submit-btn" @click="onConfirm">{{ $t('I39') }}（￥{{ SumPrice }}）</button>
        </div>
    </div>
</template>

<script>
import { pageAllCar, pageAllCarService } from '@/api/home'
export default {
    name: 'carList',
    data() {
        return {
            checkList: [],
            checkList2: [],
            count: 1, //总数据数量
            pageNum: 1,
            list: [], //车辆列表
            serveList: [],
            type: 0,
            from: {},
            serveLists: [], //选中的服务id列表
            changeCar: [], //选中的车辆id列表
            changeCarList: [], //选中的车辆列表
            severList: [], //选中的服务列表
        }
    },
    created() {
        this.getList()
        this.type = this.$route.query.type
        this.from = JSON.parse(decodeURIComponent(this.$route.query.from))
        console.log(this.type)
        console.log(this.from)
        pageAllCarService().then(res => {
            res.rows.forEach((item, index) => {
                item.num = 1
                item.idd = index + 1
            })
            this.serveList = res.rows
        })
    },
    computed: {
        carPrice() {
            let newList = this.list.filter((item) => {
                return this.changeCar.includes(item.id)
            })
            let price = 0
            newList.map(item => {
                price += item.num * item.unitPrice
            })
            return price
        },
        ServePrice() {
            let newList = this.serveList.filter((item) => {
                return this.serveLists.includes(item.idd)
            })
            this.severList = newList
            console.log(newList)
            let price = 0
            newList.map(item => {
                price += item.num * item.servicePrice
            })
            return price
        },
        SumPrice() {
            return this.carPrice + this.ServePrice
        },
    },
    methods: {
        getList() {
            pageAllCar(this.pageNum, 4).then(res => {
                res.rows.forEach(item => {
                    item.num = 1
                })
                this.list = res.rows
                this.count = res.total
            })
        },
        change1(e) {
            this.changeCar = e
            console.log(e)
        },
        change2(e) {
            this.serveLists = e
            console.log(e)
        },
        currentChange(e) {
            this.pageNum = e
            this.getList()
        },
        onConfirm() {
            if (!this.changeCar.length) {
                this.$message.warning('请选择车辆')
                return
            }
            this.changeCar.forEach(item => {
                this.list.forEach(i => {
                    if (item == i.id) {
                        this.changeCarList.push(i)
                    }
                })
            })
            this.$router.push({
                path: '/confirm', query: {
                    type: this.type,
                    from: encodeURIComponent(JSON.stringify(this.from)),
                    carList: encodeURIComponent(JSON.stringify(this.changeCarList)),
                    severList: encodeURIComponent(JSON.stringify(this.severList)),
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    background: #fff;
    height: calc(100vh - 138px);
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 12px;
    padding: 20px;
}

.serveList {
    margin-top: 2em;

    .items {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    ::v-deep .el-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 60px;
    }

    .ins {
        display: flex;
        gap: 8px;
        align-items: center;

        img {
            width: 16px;
            height: 16px;
        }

        font-size: 19px;

        span {
            color: #ff0909;
        }
    }
}



::v-deep .el-checkbox__inner::after {
    height: 17px;
    left: 10px;
}

::v-deep .el-checkbox__inner {
    width: 24px;
    height: 24px;
    border: 1px solid #808080;
}

::v-deep .el-checkbox__label {
    display: none;
}

.carList {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    ::v-deep .el-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px 0;
    }

    .item {
        width: 50%;
        display: flex;
        gap: 30px;
        align-items: center;

        .info {
            margin-top: 14px;
            display: flex;
            gap: 10px;

            .right {
                img {
                    width: 154px;
                    height: 134px;
                    object-fit: cover;
                }
            }

            .left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .name {
                    font-size: 18px;
                    font-weight: 400;
                    color: #000000;
                }

                .infos {
                    font-size: 16px;
                    font-weight: 400;
                    color: #808080;
                    width: 85%;
                    line-height: 22px;
                }

                .serve {
                    display: flex;
                    gap: 50px;

                    .ins {
                        display: flex;
                        gap: 4px;

                        img {
                            width: 16px;
                            height: 16px;
                        }

                        font-size: 16px;

                        span {
                            color: #ff0909;
                        }
                    }
                }

                .price {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    font-size: 20px;

                    span {
                        color: #ff0909;
                    }
                }
            }
        }
    }

}

.paging {
    margin-top: 30px;
    float: right;
}

.btnbox {
    width: 25%;
    margin: 40px 0;
}
</style>