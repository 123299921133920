import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'
import centre from '../views/mine/center.vue'
import carList from '../views/menu/carList.vue'
import confirm from '../views/menu/confirm.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home, meta: { title: '首页' } },
  { path: '/carList', component: carList, meta: { title: '车辆列表' } },
  { path: '/confirm', component: confirm, meta: { title: '确认订单' } },
  {
    path: '/centre', component: centre, meta: { title: '个人中心' },
    children: [
      { path: 'order', component: () => import('@/views/mine/menu/order.vue'), meta: { title: '我的订单' } },
      { path: 'firm', component: () => import('@/views/mine/menu/firm.vue'), meta: { title: '企业中心' } },
      { path: 'promotion', component: () => import('@/views/mine/menu/promotion.vue'), meta: { title: '推广中心' } },
      { path: 'finance', component: () => import('@/views/mine/menu/finance.vue'), meta: { title: '财务中心' } },
      { path: 'set', component: () => import('@/views/mine/menu/set.vue'), meta: { title: '设置' } },
      { path: 'changeMessage', component: () => import('@/views/mine/menu/changeMessage.vue'), meta: { title: '修改信息' } },
      { path: 'Link', component: () => import('@/views/mine/menu/Link.vue'), meta: { title: '专属链接' } },
      { path: 'charteredInfo', component: () => import('@/views/mine/menu/charteredInfo.vue'), meta: { title: '包车订单' } },
      { path: 'tailoredInfo', component: () => import('@/views/mine/menu/tailoredInfo.vue'), meta: { title: '专车订单' } },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
